:host {
  display: block;
}

::slotted(*:not(:last-child)) {
  margin-bottom: var(--sc-form-row-spacing, 0.75em);

  &.wp-block-spacer {
    margin-bottom: 0;
  }
}
::slotted(*:focus-within),
::slotted(*:active) {
  position: relative;
  z-index: 5;
}
